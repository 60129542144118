<template>
  <div class="container">
    <div class="title_Login">
      <div v-if="changeLogin == 1">
        <div @click="toCodeLogin" class="title title_top">
          <div :class="changeLogin == 2 ? 'change_login normal' : 'normal'">验证码登录</div>
        </div>
        <div @click="toLogin" class="title ">
          <div :class="changeLogin == 1 ? 'change_login ' : '' ">
            账号密码登录
          </div>
        </div>
      </div>

      <div v-else-if="changeLogin == 2">
        <div @click="toLogin" class="title title_top">
          <div :class="changeLogin == 1 ? 'change_login normal' : 'normal'">
            账号密码登录
          </div>
        </div>
        <div @click="toCodeLogin" class="title ">
          <div :class="changeLogin == 2 ? 'change_login ' : ' '">验证码登录</div>
        </div>
      </div>
    </div>
    <div>
      <el-form
        :model="loginForm"
        :rules="loginFormRules"
        class="demo-ruleForm"
        ref="loginFormRef"
      >
        <el-form-item prop="phone" ref="phone">
          <img
            alt=""
            class="img_l"
            src="../../../assets/img/login/username.png"
          />
          <el-input
            maxlength="11"
            placeholder="请输入手机号"
            v-model="loginForm.phone"
          ></el-input>
        </el-form-item>
        <el-form-item
          :prop="changeLogin == 1 ? 'password' : ''"
          ref="password"
          v-if="changeLogin == 1"
        >
          <img
            alt=""
            class="img_l"
            src="../../../assets/img/login/password.png"
          />
          <el-input
            :type="isShowPwd ? 'password' : 'text'"
            maxlength="20"
            placeholder="请输入密码"
            v-model="loginForm.password"
          ></el-input>
          <img
            @click="changePwdStatus()"
            alt=""
            class="img_r"
            src="../../../assets/img/login/eye01.png"
            v-if="!isShowPwd"
          />
          <img
            @click="changePwdStatus()"
            alt=""
            class="img_r"
            src="../../../assets/img/login/eye01.png"
            v-if="isShowPwd"
          />
        </el-form-item>

        <el-form-item
          :prop="changeLogin == 2 ? 'code' : ''"
          ref="code"
          v-else-if="changeLogin == 2"
        >
          <img alt="" class="img_l" src="@/assets/img/reset/code.png"/>
          <el-input
            maxlength="20"
            placeholder="请输入短信验证码"
            v-model="loginForm.code"
          ></el-input>
          <!-- 短信验证码 -->
          <div class="phoneCode">
            <span @click="getCode" class="code" v-show="showCode"
            >获取验证码</span
            >
            <span class="count" v-show="!showCode">{{ count }} s</span>
          </div>
        </el-form-item>
        <el-form-item style="margin: 40px 0 0 0"></el-form-item>
        <el-form-item>
          <el-button @click="submitForm()">登 录</el-button>
        </el-form-item>
        <div class="box" v-if="changeLogin == 1">
          <div>
            <div @click="register()" class="fr">还没有账号？立即注册</div>
            <div @click="forgetPwd()" class="fl">忘记密码？</div>
          </div>
          <!-- <div class="fc">忘记密码请联系管理员邮箱：service@campcenter.cn</div> -->
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
  /* eslint-disable */
  import {login, loginCode} from "r/base/login";
  import {mobileCheck} from "@/common/utils.js";
  import {intervalTime} from "store/time";

  export default {
    name: "base-login",
    data() {
      return {
        isShowPwd: true, // 控制密码显示隐藏
        // 手机验证短信
        showCode: true,
        count: "",
        timer: null,
        codeTime: "", //手机验证码倒计时时间
        loginForm: {
          phone: null,
          password: null,
          code: null,
        },
        // 表单验证规则对象
        loginFormRules: {
          // 对手机号进行校验
          phone: [
            {required: true, message: "请输入手机号", trigger: "blur"},
            {
              validator: function (rule, value, callback) {
                if (mobileCheck(value) === false) {
                  callback(new Error("请输入正确的手机号"));
                } else {
                  callback();
                }
              },
              trigger: "blur",
            },
          ],
          // 对密码进行校验
          password: [
            {
              required: true,
              message: "请输入密码",
              transform: (value) => value,
              trigger: "blur",
            },
            {
              type: "string",
              message: "请输入不包含空格的字符",
              trigger: "blur",
              transform(value) {
                if (value && value.indexOf(" ") === -1) {
                  return value;
                } else {
                  return false;
                }
              },
            },
            {
              trigger: "blur",
              validator: (rule, value, callback) => {
                var passwordreg = /^[a-zA-Z0-9]{6,12}$/;
                // /(?=.*\d)(?=.*[a-zA-Z]).{6,12}/;
                if (!passwordreg.test(value)) {
                  callback(new Error("请输入6-12位，包含数字/字母,"));
                } else {
                  callback();
                }
              },
            },
          ],
          // 对验证码进行校验
          code: [
            {required: true, message: "请输入验证码", trigger: "blur"},
            {min: 6, max: 20, message: "请输入正确的验证码", trigger: "blur"},
          ],
        },
        changeLogin: 1,
      };
    },
    created() {
      this.$emit("getStatus", false);
      const endTime = window.localStorage.getItem("login_code_time");
      const phone = window.localStorage.getItem("login_code_phone");
      if (endTime > 0) {
        this.loginForm.phone = phone;
        this.intervalHandleNew(endTime);
      }
      this.commonApi(39)
    },
    methods: {
      // 定时器倒计时
      intervalHandleNew(timeLag) {
        if (!this.timer) {
          this.count = timeLag - 1;
          this.showCode = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= timeLag) {
              this.count--;
              window.localStorage.setItem("login_code_time", this.count);
            } else {
              clearInterval(this.timer);
              this.showCode = true;
              this.timer = null;
            }
          }, 1000);
        }
      },
      // 定时器倒计时
      intervalHandle(startTime, endTime) {
        // 时间差
        const timeLag = intervalTime(startTime, endTime);
        if (!this.timer) {
          this.count = timeLag - 1;
          this.showCode = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= timeLag) {
              this.count--;
            } else {
              this.showCode = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      },
      // 获取手机验证短信
      getCode() {
        if (!this.loginForm.phone) {
          return this.$message.error("请输入手机号");
        }
        // 接口获得验证码
        loginCode({
          phone: this.loginForm.phone,
        }).then((res) => {
          console.log(res, "passwordCode");
          if (res.data.code != 200) {
            return this.$message.error(res.data.msg);
          }
          this.$message.success(res.data.msg);

          this.codeTime = res.data.expire_time;
          window.localStorage.setItem("login_code_time", res.data.expire_time);
          window.localStorage.setItem("login_code_phone", this.loginForm.phone);
          this.intervalHandleNew(res.data.expire_time)
        });
      },
      // 忘记账号/密码
      forgetPwd() {
        this.$router.push({
          path: '/baseReset',
          query: {
            ...this.$store.state.query,
          }
        });
      },
      // 去注册
      register() {
        this.$router.push({
          path: '/baseRegister',
          query: {
            ...this.$store.state.query,
          }
        });
      },
      // 改变密码显示状态
      changePwdStatus() {
        this.isShowPwd = !this.isShowPwd;
      },
      toLogin() {
        this.loginForm = {};
        this.changeLogin = 1;
        this.$nextTick(() => {
          this.$refs["loginFormRef"].clearValidate();
        });
        this.commonApi(39)
      },
      toCodeLogin() {
        this.loginForm = {};
        this.changeLogin = 2;
        this.$nextTick(() => {
          this.$refs["loginFormRef"].clearValidate();
        });
        this.commonApi(39)
      },
      // 登录
      submitForm() {
        this.$refs["loginFormRef"].validate((valid) => {
          if (!valid) return;
          let obj = {};
          if (this.changeLogin == 1) {
            obj.phone = this.loginForm.phone;
            obj.password = this.loginForm.password;
          } else {
            obj.phone = this.loginForm.phone;
            obj.code = this.loginForm.code;
          }
          login(obj).then((res) => {
            console.log(res, "login");
            if (res.data.code != 200) {
              return this.$message.error(res.data.msg);
            }
            this.$message.success(res.data.msg);
            this.$store.commit('login', res.data.token);
            window.localStorage.setItem("index-phone-all", this.loginForm.phone);
            window.localStorage.setItem("index-email-all", res.data.email);
            let previousPath = this.$store.state.previousPath;
            this.$router.push({
              path: previousPath,
              query: {
                ...this.$store.state.query,
              }
            });
            this.$store.commit('setPreviousPath', '/');
          });
        });
      },
    },
    destroyed() {
      this.timer && clearInterval(this.timer);
      localStorage.removeItem('login_code_time');
      localStorage.removeItem('login_code_phone');
    }
  };
</script>

<style lang="scss" scoped>
  @media screen and (max-width: 1500px) {
    .container {
      height: 300px !important;
      padding: 30px 37px 70px 37px !important;

      .title_Login {
        margin: 0 !important;

        .title {
          font-size: 12px !important;

        }

        .title_top {
          display: flex;
          justify-content: flex-end;
        }

        .normal {
          cursor: pointer
        }

        .change_login {
          margin-top: 5px !important;
          font-size: 18px !important;

        }
      }

      ::v-deep .el-form {
        margin-top: 25px !important;

        .el-form-item {
          /*margin-bottom: 25px !important;*/

          .el-form-item__content {
            img.img_l {
              width: 24px !important;
            }

            .el-input {
              font-size: 12px !important;
            }

            .el-input__inner {
              height: 40px !important;
            }

            .el-button {
              font-size: 15px !important;
              /*margin-top:10px !important;*/
              height: 50px !important;
              line-height: 50px !important;
            }
          }
        }
      }
    }


  }


  .flex {
    display: flex;
  }

  .container {
    height: 440px;
    background: #ffffff;
    box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 40px 60px;
    margin-top: 0;
    overflow: hidden;

    .title_Login {
      position: relative;
      margin: 19px 0 0 0;
      text-align: center;

      .title {
        height: 20px;
        font-size: 16px;
        font-weight: 500;
        color: var(--all_color);
        line-height: 20px;
        text-align: right;
      }

      .change_login {
        font-size: 22px;
        margin-top: 32px;
        color: black;
        text-align: center;
      }
    }

    .el-form {
      margin-top: 60px;

      .el-form-item {
        position: relative;
        margin-bottom: 30px;

        &:last-child {
          margin: 0;
        }

        .phoneCode {
          position: absolute;
          z-index: 999;
          top: 50%;
          right: 25px;
          transform: translate(0, -50%);
          cursor: pointer;

          .code {
            font-size: 12px;
            font-weight: 600;
            color: var(--all_color);
          }
        }

        img.img_l {
          position: absolute;
          left: 25px;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 1000;
        }

        img.img_r {
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translate(0, -50%);
          z-index: 1000;
        }

        ::v-deep .el-input__inner {
          height: 50px;
          line-height: 50px;
          padding: 0 10px 0 50px;
          border-radius: 0;
          color: #4d4d4d;
          border: 0;
          border-bottom: 2px solid #d9d9d9;

          &:focus {
            border-bottom-color: var(--all_color);
          }
        }

        ::v-deep .el-form-item__error {
          color: #e0823d;
        }

        .el-button {
          width: 100%;
          height: 60px;
          background: var(--all_color);
          border-radius: 4px;
          opacity: 0.8;
          line-height: 50px;
          text-align: center;
          color: #fff;
          font-size: 16px;
          padding: 0;
          margin: 30px 0 0 0;
          border: none;
        }
      }

      .box {
        font-size: 12px;
        height: 18px;
        // display: block;
        display: flex;
        flex-direction: column;

        .fl {
          float: right;
          height: 18px;
          line-height: 18px;
          color: #999999;
          margin-top: -10px;
          cursor: pointer;

          &:hover {
            color: var(--all_color);
            text-decoration: underline;
          }
        }

        .fr {
          float: left;
          height: 18px;
          line-height: 18px;
          color: #999999;
          margin-top: -10px;
          cursor: pointer;

          &:hover {
            color: var(--all_color);
            text-decoration: underline;
          }
        }

        .fc {
          font-size: 4px;
          float: right;
          // height: 18px;
          line-height: 18px;
          color: #999999;
          margin-top: 10px;
          // cursor: pointer;
          // &:hover {
          //   color: #563279;
          //   text-decoration: underline;
          // }
        }
      }
    }
  }
</style>
